<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :search="search"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': '',
          }"
        >
          <template slot="no-data">
            {{ $t('table.messages.no_data') }}
          </template>
          <template slot="no-results">
            {{ $t('table.messages.no_results') }}
          </template>
          <template
            v-slot:footer.page-text=""
            class="mr-0"
          >
            {{ $t('table.messages.page') }}
            {{ options.page }}
          </template>
          <template v-slot:top>
            <v-container class="mt-5">
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="forth--text text-h4 font-weight-bold ">{{
                      $t('messages.search_by_name')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex ">
                <v-col cols="10">
                  <div class="align-self-center mr-9">
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search pa-0 "
                      outlined
                    />
                  </div>
                </v-col>
                <v-col
                  cols="2"
                  class="d-flex align-end p-0"
                >
                  <div class=" mb-9">
                    <v-btn
                      class="h-100"
                      large
                      color="primary"
                      @click="onSearch"
                    >
                      <span class="pr-5 pl-5 font-weight-bold text-h5">
                        {{ $t('table.messages.search') }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.index="props">
            {{ props.index + 1 }}
          </template>
          <template v-slot:item.name="props">
            <a @click="$router.push('/report-preview/' + props.item.id)">{{
              props.item.name
            }}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'EmployeeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [],
        editedIndex: -1,
        items: [],
        currentOptions: {},
        search: '',
        loading: false,
        totalItems: 1,
      }
    },

    mounted () {
      this.items = [
        { name: 'タイムシートレポート' },
        { name: '28時間レポート' },
        { name: '顧客とパートタイマー' },
        { name: 'パートタイマーレポート' },
      ]
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          width: '10%',
          class: 'font-weight-bold',
          sortable: true,
        },
        {
          text: this.$t('table.headers.report_name'),
          align: 'start',
          value: 'name',
          class: 'font-weight-bold',
          sortable: true,
        },
      ]
    },
  }
</script>
